import React, { Component } from "react";
import { Input } from "./styleguide";
import Button from "./styleguide/Button/index";

class Calculate extends Component {
  state = {
    width: "",
    depth: "",
    length_m: "",
    showResult: false,
    susage: 0,
    volume: 0
  };

  handleChange = ({ currentTarget: { value } }, element) => {
    if (isNaN(value) && value.length > 0)
      return this.setState({ ...this.state });
    const newState = this.state;

    newState[element] = value;
    this.setState({ ...newState }, this.calculateSusage);
  };

  handleGeneratePdf = () => {
    let Html2Pdf = require("js-html2pdf");
    const element = document.getElementById("calculator");
    const options = {
      filename: "kalkulator.pdf"
    };
    const exporter = new Html2Pdf(element, options);

    exporter.getPdf(true)
  };

  calculateSusage = ({ width, length_m, depth } = this.state) => {
    if (width > 0 && length_m > 0 && depth > 0) {
      const volume = width * length_m * 100 * depth,
        susage = Math.round(volume / 1000 / 0.6);
      this.setState({
        showResult: true,
        volume,
        susage: susage < 1 ? 1 : susage
      });
    } else {
      this.setState({ showResult: false, volume: 0, susage: 0 });
    }
  };

  renderSusage = rest => {
    let { susage } = this.state;
    if (rest) susage = rest;
    if (susage === 1) return "kiełbaskę";
    if (susage > 1 && susage < 5) return "kiełbaski";
    return "kiebłasek";
  };

  renderCartons = () => {
    const cartons = Math.floor(this.state.susage / 20).toFixed(0);
    if (cartons < 1) return "kartona";
    if (cartons === 1) return "karton";
    if (cartons > 1 && cartons < 5) return "kartony";
    return "kartonów";
  };

  renderResult = () => {
    const { susage } = this.state,
      cartons = Math.floor(susage / 20).toFixed(0),
      rest = Math.round(susage % 20);
    if (cartons < 1)
      return (
        <>
          1 niepełny karton x {rest} {this.renderSusage()}
        </>
      );
    if (cartons > 1 && rest < 1)
      return (
        <>
          {cartons} {this.renderCartons()} x 20 kiełbasek
        </>
      );
    return (
      <>
        {cartons} {this.renderCartons()} x 20 kiełbasek + 1 karton niepełny x{" "}
        {rest} {this.renderSusage(rest)}
      </>
    );
  };

  render() {
    const { width, depth, length_m, showResult, susage, volume } = this.state;
    return (
      <>
        <div className="panel-calculator" id="calculator">
          <p>
            Kalkulator: wylicz swoje zapotrzebowanie masy poliuretanowej PU 40
            do wypełnienia dylatacji. Proszę podać szerokość, głębokość i
            długość szczeliny.
          </p>
          <div className="calculator-wrapper">
            <Input
              label={"Szerokość"}
              onChange={e => this.handleChange(e, "width")}
              value={width}
              postfix="[cm]"
            />
            <Input
              label={"Głębokość"}
              onChange={e => this.handleChange(e, "depth")}
              value={depth}
              postfix="[cm]"
            />
            <Input
              label={"Długość"}
              onChange={e => this.handleChange(e, "length_m")}
              value={length_m}
              postfix="[m]"
            />
          </div>
          {showResult && (
            <div className="calculator-results">
              <div className="columns-wrapper">
                <div className="column">
                  <h3>Wynik:</h3>
                  <p>Długość: {`${(length_m * 100).toFixed(0)} [cm]`} </p>
                  <p>Objętość: {`${volume.toFixed(0)} [cm3]`} </p>
                  <p>Objętość: {`${(volume / 1000).toFixed(1)} [litrów]`} </p>
                  <p>
                    <small>*1000 cm3 to 1 litr</small>
                  </p>
                </div>
                <div className="column">
                  <h3>Zapotrzebowanie:</h3>
                  <p>Kiełbaska to 600ml czyli 0.6 litra.</p>
                  <p>
                    Zapotrzebowanie: {susage} {this.renderSusage()}
                  </p>
                  <p>
                    Potrzebujesz: {susage / 20} {this.renderCartons()}{" "}
                  </p>
                  <p>
                    <small>*w kartonie jest 20 kiełbasek</small>
                  </p>
                </div>
              </div>

              <h3>Podsumowanie</h3>
              <p>Przy zamówieniu podaj wartość poniższego wyliczenia:</p>
              <p className="final-result" ref={c => (this.result = c)}>
                {this.renderResult()}
              </p>
              <p>
                <strong>Uwaga</strong>: Dodatkowo na 2 kartony masy
                poliuretanowej PU40 wymaga się użycia 1 litra PU Primer RS
                (1000ml).
              </p>
            </div>
          )}
        </div>
        {showResult && (
          <div className="download-pdf__container">
            <Button
              fill
              content="Pobierz pdf"
              onClick={() => this.handleGeneratePdf()}
            />
          </div>
        )}
      </>
    );
  }
}

export default Calculate;
