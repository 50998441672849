import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

const NavigationPrev = ({ innerProps }) => (
  <button {...innerProps} className='gallery-arrow prev'>
    <img className='lazyload' data-src={require("../images/icons/slick-prev.svg")} alt='Prev arrow' />
  </button>
);
const NavigationNext = ({ innerProps }) => (
  <button {...innerProps} className='gallery-arrow next'>
    <img className='lazyload' data-src={require("../images/icons/slick-next.svg")} alt='Next arrow' />
  </button>
);
const HeaderClose = ({ innerProps }) => (
  <button {...innerProps} className='gallery-close'>
    <img className='lazyload' data-src={require("../images/icons/gallery_close.svg")} alt='Close icon' />
  </button>
);

export default class Gallery extends React.Component {
  state = {
    selectedIndex: 0,
    modalIsOpen: false,
  };
  toggleModal = selectedIndex => {
    const { modalIsOpen } = this.state;
    this.setState({ modalIsOpen: !modalIsOpen, selectedIndex });
  };
  render() {
    const { modalIsOpen, selectedIndex } = this.state;
    const { images, white } = this.props;
    if (!images || images.length < 1) return null;
    return (
      <>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal
              onClose={this.toggleModal}
              allowFullscreen={false}
              styles={{
                blanket: base => ({
                  ...base,
                  zIndex: 100,
                  background: "rgba(255,255,255,.8)",
                }),
                positioner: base => ({
                  ...base,
                  zIndex: 100,
                }),
              }}
            >
              <Carousel
                views={images}
                currentIndex={selectedIndex}
                components={{ NavigationPrev, NavigationNext, HeaderClose }}
                selectedIndex={selectedIndex}
                hideControlsWhenIdle={false}
                styles={{
                  footer: base => ({
                    ...base,
                    display: "none",
                  }),
                  header: base => ({
                    ...base,
                    background: "transparent!important",
                    maxWidth: "1200px",
                    padding: "25px 10px",
                    margin: "0 auto",
                  }),
                  container: base => ({
                    ...base,
                    maxWidth: "1080px",
                  }),
                  navigationItem: base => ({
                    ...base,
                    backgroundColor: "transparent",
                    zIndex: "100",
                  }),
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <div className='grid gallery-grid x3'>
          {images.map(({ src, title }, index) => (
            <div
              key={index}
              onClick={() => this.toggleModal(index)}
              style={{ backgroundImage: `url('${src}')` }}
              className={`lazyload grid-element gallery-item ${white ? "white" : ""}`}
            />
          ))}
        </div>
      </>
    );
  }
}
