import React, { Component } from "react";
import { Player, BigPlayButton } from "video-react";
import Layout from "../components/layout";
import {
  Breadcrumbs,
  Button,
} from "../components/styleguide";
import { CookiesProvider, withCookies } from "react-cookie";
import Gallery from "../components/gallery";
import Calculate from "../components/calculate";
import MarkdownRenderer from "react-markdown-renderer";
import Carousel, {
  Modal,
  ModalGateway,
} from "react-images";
import ReactHtmlParser from "react-html-parser";

const HeaderClose = ({ innerProps }) => (
  <button {...innerProps} className="gallery-close">
    <img
      className="lazyload"
      data-src={require("../images/icons/gallery_close.svg")}
      alt="Close icon"
    />
  </button>
);
const NavigationPrev = ({ innerProps }) => (
  <button {...innerProps} className="gallery-arrow prev">
    <img
      className="lazyload"
      data-src={require("../images/icons/slick-prev.svg")}
      alt="Prev arrow"
    />
  </button>
);
const NavigationNext = ({ innerProps }) => (
  <button {...innerProps} className="gallery-arrow next">
    <img
      className="lazyload"
      data-src={require("../images/icons/slick-next.svg")}
      alt="Next arrow"
    />
  </button>
);

class ProductPage extends Component {
  constructor(props) {
    super(props);
    const { data, common } = this.props.pageContext;
    const [translations] = common.translations;
    this.state = {
      activeTab: "description",
      descImages: [],
      selectedIndex: 0,
      modalIsOpen: false,
    };
    this.nav = [
      data.main_desc
        ? {
            title: translations.description,
            name: "description",
          }
        : null,
      data.spec_tech ||
      (data.product_gallery &&
        data.product_gallery.length > 0)
        ? {
            title: translations.spec_tech,
            name: "specification",
          }
        : null,
      data.video_link || data.video
        ? { title: translations.video, name: "video" }
        : null,
      data.attachment
        ? {
            title: translations.attachments,
            name: "attachment",
          }
        : null,
      data.calculator && data.calculator === "true"
        ? {
            title: translations.calculator,
            name: "calculate",
          }
        : null,
    ].filter((item) => item !== null);
  }

  toggleModal = (selectedIndex) => {
    const { modalIsOpen } = this.state;
    this.setState({
      modalIsOpen: !modalIsOpen,
      selectedIndex,
    });
  };

  componentDidMount() {
    const table = document.querySelector("table") || false;
    if (table) {
      table.parentNode.classList = "table-overflow";
    }
    const mainDescContainer =
      document.getElementById("imagesWrapper");
    const articles = Array.from(
      document.querySelectorAll("article")
    ).filter((element) => element.children.length > 2);
    if (!mainDescContainer) return false;
    const images = Array.from(
      mainDescContainer.getElementsByTagName("img")
    );
    if (images.length < 1) return false;
    const descImages = images
      .filter(
        (element) => element.parentNode.tagName !== "A"
      )
      .map((image, index) => ({
        src: image.src || image.dataset.src,
        title: "",
        index,
      }));

    images.forEach((element, index) => {
      if (element.parentNode.tagName !== "A") {
        element.style.cursor = "pointer";
        element.addEventListener("click", () =>
          this.toggleModal(index)
        );
      }
    });
    this.setState({
      descImages,
      activeTab: this.nav[0] ? this.nav[0].name : null,
    });
    Array.from(document.querySelectorAll(".table a")).map(
      (element) => element.setAttribute("target", "_blank")
    );

    articles.forEach((element) => {
      element.classList.add("product-info-row");
      return Array.from(element.children).find((child) => {
        if (
          child.children &&
          child.children.length > 0 &&
          child.children[0] &&
          child.children[0].tagName === "IMG"
        ) {
          return child.classList.add(
            "product-info-row__image"
          );
        }
        return;
      });
    });
  }

  transformHtml() {
    const { data, common } = this.props.pageContext;
    const html = ReactHtmlParser(data.main_desc);
    const transformedHtml = html.map((node) => {
      if (node && node.type === "iframe") {
        const { children, src, ...rest } = node.props;
        return (
          <React.Fragment key={node.key}>
            {common.lang === "pl" ? (
              <div className="cookieconsent-optout-marketing">
                Proszę{" "}
                <a href="javascript:Cookiebot.renew()">
                  zaakceptować marketingowe pliki cookies
                  klikając tutaj
                </a>
                , aby obejrzeć ten film.
              </div>
            ) : (
              <div className="cookieconsent-optout-marketing">
                Please{" "}
                <a href="javascript:Cookiebot.renew()">
                  accept marketing-cookies by clicking here
                </a>
                , to watch this video.
              </div>
            )}
            <iframe
              data-src={src}
              data-cookieconsent="marketing"
              {...rest}
            />
          </React.Fragment>
        );
      }
      if (
        node &&
        node.type === "article" &&
        node.props.children &&
        node.props.children.length > 0 &&
        node.props.children[0].type === "iframe"
      ) {
        const { children, src, ...rest } =
          node.props.children[0].props;
        return (
          <article
            key={node.key}
            style={{ display: "block" }}
          >
            {common.lang === "pl" ? (
              <div className="cookieconsent-optout-marketing">
                Proszę{" "}
                <a href="javascript:Cookiebot.renew()">
                  zaakceptować marketingowe pliki cookies
                  klikając tutaj
                </a>
                , aby obejrzeć ten film.
              </div>
            ) : (
              <div className="cookieconsent-optout-marketing">
                Please{" "}
                <a href="javascript:Cookiebot.renew()">
                  accept marketing-cookies by clicking here
                </a>
                , to watch this video.
              </div>
            )}
            <iframe
              data-src={src}
              data-cookieconsent="marketing"
              {...rest}
            />
          </article>
        );
      }
      return node;
    });
    return transformedHtml;
  }

  render() {
    const {
      activeTab,
      descImages,
      selectedIndex,
      modalIsOpen,
    } = this.state;
    const {
      cookies,
      pageContext: {
        data,
        common,
        breadcrumbs,
        productsPath,
        contactPath,
      },
    } = this.props;
    const [translations] = common.translations;
    const tabsNav = this.nav;
    return (
      <CookiesProvider>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal
              onClose={this.toggleModal}
              allowFullscreen={false}
              styles={{
                blanket: (base) => ({
                  ...base,
                  zIndex: 100,
                  background: "rgba(255,255,255,.8)",
                }),
                positioner: (base) => ({
                  ...base,
                  zIndex: 100,
                }),
              }}
            >
              <Carousel
                views={descImages}
                currentIndex={selectedIndex}
                components={{
                  NavigationPrev,
                  NavigationNext,
                  HeaderClose,
                }}
                selectedIndex={selectedIndex}
                hideControlsWhenIdle={false}
                styles={{
                  footer: (base) => ({
                    ...base,
                    display: "none",
                  }),
                  header: (base) => ({
                    ...base,
                    background: "transparent!important",
                    maxWidth: "1200px",
                    padding: "25px 10px",
                    margin: "0 auto",
                  }),
                  container: (base) => ({
                    ...base,
                    maxWidth: "1080px",
                  }),
                  navigationItem: (base) => ({
                    ...base,
                    backgroundColor: "transparent",
                    zIndex: "100",
                  }),
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Layout
          data={common}
          title={
            data.seoTitle && data.seoTitle.length > 0
              ? data.seoTitle
              : `${data.name} - DMV-PLUS`
          }
          description={
            data.seoDescription &&
            data.seoDescription.length > 0
              ? data.seoDescription
              : common.static_data[0].product_seo_description.replace(
                  "$product",
                  data.name.toLowerCase()
                )
          }
          hrefLang={data.hrefLang}
        >
          <div className="main-container">
            <Breadcrumbs
              data={breadcrumbs}
              lang={common.lang}
            />
            <div
              className="product-container"
              id="imagesWrapper"
            >
              <div className="product-main-info">
                <h1 className="product-main-info--title">
                  <MarkdownRenderer markdown={data.title} />
                </h1>
                <div
                  className={`product-main-info--wrapper ${
                    !data.main_image_top ? "full" : ""
                  }`}
                >
                  <div className="column">
                    <MarkdownRenderer
                      markdown={data.short_desc}
                    />
                    <div className="product-main-info--buttons-wrapper desktop">
                      <Button
                        type="link"
                        href={productsPath}
                        fill
                        content={
                          translations.go_to_products
                        }
                        size="sm"
                      />
                      <Button
                        type="link"
                        href={contactPath}
                        fill
                        content={translations.contact_us}
                        size="sm"
                      />
                    </div>
                  </div>
                  <div className="column images">
                    <img
                      className="lazyload"
                      data-src={data.main_image_top}
                      alt={data.title.replace(
                        /(?:__|[*#])|\[(.*?)\]\(.*?\)/gm,
                        ""
                      )}
                    />
                    <div className="product-main-info--buttons-wrapper mobile">
                      <Button
                        type="link"
                        href={productsPath}
                        fill
                        content={
                          translations.go_to_products
                        }
                        size="sm"
                      />
                      <Button
                        type="link"
                        href={contactPath}
                        fill
                        content={translations.contact_us}
                        size="sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {tabsNav && tabsNav.length > 0 && (
                <div className="product-tabs">
                  <div className="tabs-nav">
                    {tabsNav.map(({ title, name }) => {
                      const width = `calc(${
                        100 / tabsNav.length
                      }% - ${30 - 30 / tabsNav.length}px)`;
                      return (
                        <button
                          key={name}
                          type="button"
                          style={{
                            flexBasis: width,
                            maxWidth: width,
                          }}
                          className={`nav-item ${
                            activeTab === name
                              ? " active"
                              : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              activeTab: name,
                            })
                          }
                        >
                          {title}
                        </button>
                      );
                    })}
                  </div>
                  <div className="tabs-panel">
                    <div
                      className={`panel ${
                        activeTab === "description"
                          ? "active"
                          : ""
                      } `}
                    >
                      {this.transformHtml()}
                      {data.product_gallery &&
                        data.product_gallery.length > 0 && (
                          <Gallery
                            white
                            images={data.product_gallery.map(
                              ({ image, title }) => ({
                                src: image,
                                title,
                              })
                            )}
                          />
                        )}
                    </div>

                    <div
                      className={`panel ${
                        activeTab === "specification"
                          ? "active"
                          : ""
                      } `}
                    >
                      {data.spec_tech && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.spec_tech,
                          }}
                        />
                      )}
                    </div>
                    {(data.video_link || data.video) && (
                      <div
                        className={`panel ${
                          activeTab === "video"
                            ? "active"
                            : ""
                        } `}
                      >
                        <h2>
                          <MarkdownRenderer
                            markdown={data.video_title}
                          />
                        </h2>
                        {data.video_link && (
                          <>
                            <iframe
                              data-src={`https://www.youtube.com/embed/${data.video_link}`}
                              data-cookieconsent="marketing"
                              frameborder="0"
                              width="100%"
                              height="500"
                              allowfullscreen
                            />
                            {common.lang === "pl" ? (
                              <div className="cookieconsent-optout-marketing">
                                Proszę{" "}
                                <a href="javascript:Cookiebot.renew()">
                                  zaakceptować marketingowe
                                  pliki cookies klikając
                                  tutaj
                                </a>
                                , aby obejrzeć ten film.
                              </div>
                            ) : (
                              <div className="cookieconsent-optout-marketing">
                                Please{" "}
                                <a href="javascript:Cookiebot.renew()">
                                  accept marketing-cookies
                                  by clicking here
                                </a>
                                , to watch this video.
                              </div>
                            )}
                          </>
                        )}
                        {data.video && (
                          <Player
                            playsInline
                            src={data.video}
                          >
                            <BigPlayButton position="center" />
                          </Player>
                        )}
                      </div>
                    )}

                    {data.attachment && (
                      <div
                        className={`panel ${
                          activeTab === "attachment"
                            ? "active"
                            : ""
                        } `}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.attachment,
                          }}
                        />
                      </div>
                    )}
                    {data.calculator &&
                      data.calculator === "true" && (
                        <div
                          className={`panel ${
                            activeTab === "calculate"
                              ? "active"
                              : ""
                          } `}
                        >
                          <Calculate />
                        </div>
                      )}
                  </div>
                </div>
              )}
              <div className="button-holder">
                <Button
                  type="link"
                  href={
                    cookies.get("listing-href") ||
                    productsPath
                  }
                  content={translations.back_to_products}
                  fill
                  size="lg"
                />
              </div>
            </div>
          </div>
        </Layout>
      </CookiesProvider>
    );
  }
}

export default withCookies(ProductPage);
